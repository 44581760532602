// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infomaterial-js": () => import("./../../../src/pages/infomaterial.js" /* webpackChunkName: "component---src-pages-infomaterial-js" */),
  "component---src-pages-praxisteam-js": () => import("./../../../src/pages/praxisteam.js" /* webpackChunkName: "component---src-pages-praxisteam-js" */),
  "component---src-pages-qualitaetsmanagement-js": () => import("./../../../src/pages/qualitaetsmanagement.js" /* webpackChunkName: "component---src-pages-qualitaetsmanagement-js" */)
}

